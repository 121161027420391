(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/examine-wager/assets/javascripts/examine.js') >= 0) return;  svs.modules.push('/components/payment/examine-wager/assets/javascripts/examine.js');

'use strict';

const examine = async (data, cb) => {
  if (svs.core.userSession.hasRole(svs.core.userSession.roles.CUSTOMERSERVICE)) {
    cb && cb({
      message: 'Customer service login, not calling api.'
    });
  } else if (!data || !data.examinedSerial || !data.productId) {
    cb && cb({
      message: 'Missing or invalid wager data'
    });
  } else {
    try {
      if (!svs.core.detect.feature('ft-customer-bet-examine')) {
        const response = await svs.core.jupiter.callAsync({
          method: 'POST',
          path: '/wager/1/examined',
          data
        });
        cb && cb(null, {
          wagerExaminedResponse: response
        });
      } else {
        const customerBetExaminedPromise = svs.core.jupiter.callAsync({
          method: 'POST',
          path: '/customerbet/1/wager/examined',
          data: [data.examinedSerial]
        });
        const wagerExaminedPromise = svs.core.jupiter.callAsync({
          method: 'POST',
          path: '/wager/1/examined',
          data
        });
        const [customerBetExaminedResponse, wagerExaminedResponse] = await Promise.all([customerBetExaminedPromise, wagerExaminedPromise]);
        cb && cb(null, {
          customerBetExaminedResponse,
          wagerExaminedResponse
        });
      }
    } catch (err) {
      cb && cb(err, null);
    }
  }
};
setGlobal('svs.components.payment.examineWager.examine', examine);

 })(window);